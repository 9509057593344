import { Container, Heading, Box } from "@chakra-ui/react"
import { useContent } from "@app/hooks/useContent"
import { memo } from "react"

import type { Props } from "@app/templates/generic"
import type { PageProps } from "@root/types/global"

const Generic: React.FC<PageProps<Props>> = ({ page }) => {
  const { getContent } = useContent()
  const content = getContent({ content: page?.content })

  return (
    <Container pt={[16, 16, 20]} maxW="container.sm" minH="calc(100vh - 531px)" mb={[12, 24]}>
      <Heading size="h2" as="h1" mb={[6, 12]} mt={6}>
        {page?.title}
      </Heading>
      <Box mt={4}>{content}</Box>
    </Container>
  )
}

export default memo(Generic)
